import axios from "axios";
import { indonesia_payment } from "../../js/path";

export default {
  data() {
    return {
      screenshot: "",
      message: "",
      params: "",
      filter: null,
      pageNo: null,
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      filterOn: [],
      currentPage: 1,
      fields: [
        {
          key: "reference_id",
          visible: true,
        },
        {
          key: "title",
        },
        {
          key: "reference_type",
          visible: true,
        },
        {
          key: "currency",
        },
        {
          key: "quantity",
        },
        {
          key: "unit_price",
        },
      ],
    };
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData() {
      this.$store.commit("loader/updateStatus", true);
      const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;
      let request = `${indonesia_payment?.getOrderItem}/${this.$route.params.id}`;
      axios
        .get(`${apiEndpoint}${request}`)
        .then((response) => {
          const res = response?.data;
          console.log(res);
          if (res) {
            this.tableData = res?.response;
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          console.log(err);
          this.$store.commit("loader/updateStatus", false);
        });
    },
  },
  activeTab: {
    handler: function (value) {
      if (value == "trash") {
        let index = this.fields.findIndex((item) => item.key == "delete");
        this.fields.splice(index, 1);
        let edit_index = this.fields.findIndex((item) => item.key == "edit");
        this.fields.splice(edit_index, 1);
      } else if (value == "all" || value == "active") {
        if (this.fields[this.fields.length - 1].key != "delete") {
          this.fields.splice(this.fields.length, 0, { key: "edit" });
          this.fields.splice(this.fields.length, 0, { key: "delete" });
        }
      }
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.fetchData();
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    this.fetchData();
  },
};
