<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row" id="article">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive mb-4">
              <b-table
                striped
                bordered
                :items="tableData"
                :fields="fields"
                :sort-by.sync="sortBy"
                :per-page="0"
                :current-page="currentPage"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                class="table-bordered table-hover"
              >
                <template v-slot:cell(currency)="row">
                  <span
                    v-if="row.item.currency == 'INR'"
                    class="badge badge-primary"
                  >
                    India
                  </span>
                  <span
                    v-if="row.item.currency == 'IDR'"
                    class="badge badge-info"
                  >
                    Indonesia
                  </span>
                </template>
                <template v-slot:cell(reference_type)="row">
                  {{
                    row.item.reference_type == "live_event"
                      ? "Live Event"
                      : row.item.reference_type
                  }}
                </template>
                <template v-slot:cell(unit_price)="row">
                  <span v-if="row.item.unit_price && row.item.unit_price">{{
                    row.item?.currency + " " + row.item.unit_price
                  }}</span>
                  <span v-else>---</span>
                </template>
              </b-table>
            </div>
            <div class="no_content py-4" v-if="tableData?.length == 0">
              <div style="display: grid; justify-items: center">
                <img src="../../../../../../public/noData.svg" alt="" />
                <span class="no_content_text">No Data Available</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../../mixins/request";
import IndoMixin from "../../../../../mixins/ModuleJs/payment-order-item";

export default {
  mixins: [MixinRequest, IndoMixin],
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Order Items",
      items: [
        {
          text: "Back",
          href: "/order",
        },
        {
          text: "List",
        },
      ],
    };
  },
};
</script>
